import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {AuthService} from "../authService";
import {TokenDto} from "../../common/dtos/tokenDto";
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {LoggingService} from "../logging";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(private tokenService: AuthService,
              private http: HttpClient,
              private log: LoggingService,
              private router: Router) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();
    const refresh = this.tokenService.getRefresh();

    if ((token + refresh) == "" || req.url.indexOf('refresh') > -1) {
      this.log.Log(`no token avail or token refresh req`);
      return next.handle(req);
    }
    if (!this.tokenService.tokenIsExpired()) {
      return next.handle(this.addAuth(req));
    } else if (!this.tokenService.refreshIsExpired()) {
      this.log.Log(`refreshing token`)
      return this.handleRefresh(req, next);
    }
  }

  private addAuth(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set("Authorization", `Bearer ${this.tokenService.getToken()}`)
    });
  }

  private handleRefresh(request: HttpRequest<any>, next: HttpHandler) {
    this.log.Log('handle refresh');
    if (!this.isRefreshing) {
      this.log.Log('token refresh');
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.http.post<TokenDto>(`/api/user/refresh/${this.tokenService.getRefresh()}`, {})
        .pipe(
          switchMap((token: TokenDto) => {
            this.tokenService.setToken(token.token);
            this.tokenService.setRefresh(token.refresh);

            this.isRefreshing = false;
            this.refreshTokenSubject.next(token.token);
            return next.handle(this.addAuth(request));
          }),
          catchError(err => {
            this.isRefreshing = false;
            this.log.Log("Error refreshing token: ${err}");
            this.tokenService.clear();
            this.router.navigate(['/']);
            return throwError(err);
          }));
    } else {
      this.log.Log('waiting on token refresh');
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addAuth(request));
        })
      )
    }
  }
}
