import { Injectable } from '@angular/core';

@Injectable()
export class OptionsService {

  getBoolean(name: string, defaultValue?: boolean): boolean {
    const value = sessionStorage.getItem(name);
    if (defaultValue && !value) {
      return defaultValue;
    }
    if (!value) {
      return false;
    }
    return value === 'TRUE';
  }

  setBoolean(name: string, value: boolean) {
    sessionStorage.setItem(name, value ? 'TRUE' : 'FALSE');
  }

  getString(name: string, defaultValue?: string): string {
    const value = sessionStorage.getItem(name);
    if (defaultValue && !value) {
      return defaultValue;
    }
    if (!value) {
      return '';
    }
    return value;
  }

  setString(name: string, value: string) {
    sessionStorage.setItem(name, value);
  }

  constructor() { }

}
