<h3>Assessments</h3>
<div class="d-flex flex-row">
  <nav ngbNav class="nav-pills" orientation="vertical" [(activeId)]="active" #nav="ngbNav">
    <ng-container *ngFor="let subject of subjectList">
      <div [ngbNavItem]="subject" class="mr-3">
        <a ngbNavLink>{{subject}}</a>
        <ng-template ngbNavContent>
          <ng-container *ngIf="!loading">
            <sortable-table
              [headers]="headers"
              showAdd="true"
              showEdit="true"
              showDelete="true"
              [data]="data.get(subject)"
              (saveCall)="save($event, subject)"
              (delCall)="del($event)"
              [adding-data]="newRow">
            </sortable-table>
          </ng-container>
          <ng-container *ngIf="loading">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="ms-4 flex-grow-1"></div>
</div>
