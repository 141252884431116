import {Component, OnInit} from '@angular/core';
import {DistrictService} from '../../services/district.service';
import {DataType, Header, SortDirection} from '../../components/helpers/sortableTable/sortableTable.component';
import {ActivatedRoute} from '@angular/router';
import {TitleService} from '../../services/title.service';
import {DistrictAssessmentDto} from '../../common/dtos/districtAssessmentDto';
import {Level, ToastService} from '../../services/toast.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: `module-assessments`,
  templateUrl: 'assessments.component.html'
})
export class AssessmentsComponent implements OnInit {
  private subjectList: string[] = ['ELA', 'Social Studies', 'Math', 'Science'];
  data: DistrictAssessmentDto[];
  private districtId: string;
  loading: false;
  newRow: DistrictAssessmentDto = {
    id: '',
    name: '',
    enabled: true,
    subject: '',
    grades: []
  }

  headers = [
    new Header('Name', 'name', false, DataType.String, {sortOrder: SortDirection.Ascending}),
    new Header('Enabled', 'enabled', false, DataType.Boolean, {dropdownValues: ['true', 'false']}),
    new Header('Subject', 'subject', true, DataType.Select, {dropdownValues: this.subjectList}),
  ];

  ngOnInit(): void {
    this.activeRoute.parent.parent.params.subscribe(p => {
      if (p['districtId']) {
        this.districtId = p['districtId'];
        this.getData();
      }
    });
    this.titleService.setTitle('District Buildings');
  }

  save(event: DistrictAssessmentDto) {
    if(!event.id) {
      this.districtService.postAssessment(this.districtId, event).subscribe(() => {
        this.toastService.AddNotification(Level.SUCCESS, "Assessments", "Assessment added successfully");
        this.getData();
        this.newRow = {
          id: '',
          name: '',
          enabled: true,
          subject: '',
          grades: []
        }
      }, err => {
        this.toastService.AddNotification(Level.ERROR, "Assessments", "Failed to add assessment");
      });
    } else {
      this.districtService.patchAssessment(this.districtId, event).subscribe(() => {
        this.toastService.AddNotification(Level.SUCCESS, "Assessments", "Assessment updated successfully");
        this.getData();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, "Assessments", "Failed to update assessment");
      });
    }
  }

  del(event: DistrictAssessmentDto) {
    this.districtService.deleteAssessment(this.districtId, event.id).subscribe(() => {
      this.toastService.AddNotification(Level.SUCCESS, "Assessments", "Assessment deleted successfully");
      this.getData();
    }, err => {
      this.toastService.AddNotification(Level.ERROR, "Assessments", "Failed to delete assessment");
    });
    console.log(event);
  }

  getData() {
    this.districtService.getAssessments(this.districtId).subscribe(r => {
      this.data = r || [];
    }, err => {
      this.toastService.AddNotification(Level.ERROR, "Assessments", "Failed to load assessments");
    });
  }

  constructor(
    private districtService: DistrictService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private toastService: ToastService) {
  }
}
