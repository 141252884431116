
<div class="d-flex flex-column">
  <div class="ml-auto mr-auto mt-4 mb-4 col-sm-12 col-md-6 col-lg-4">
    <ng-container *ngIf="token.length === 0 && !submitted">
      <form #forgotRequest="ngForm" (ngSubmit)="requestForgotPassword()">
        <div class="form-group">
          <label for="email">Email</label>
          <input #emailInput="ngModel" type="email" class="form-control" id="email" name="email" [(ngModel)]="email" required email>
          <span *ngIf="forgotRequest.form.touched && !emailInput.valid" class="text-danger">Must be a valid email</span>
        </div>
        <button type="submit" class="btn btn-primary btn-block" [disabled]="email?.length == 0">Request Reset</button>
      </form>
    </ng-container>
    <ng-container *ngIf="token.length === 0 && submitted">
      <div class="container">
        <div class="d-flex flex-column">
          <h3>An E-Mail should be on it's way!</h3>
          <p>An email should be coming shortly with instructions to change your password</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="token.length > 0 && email.length > 0">
      <form #resetForm="ngForm" (ngSubmit)="changePassword()">
        <div class="form-group">
          <label for="password">New Password</label>
          <input type="password" class="form-control" id="password" name="token" [(ngModel)]="password" required minlength="8" #passwordInput="ngModel">
          <span class="text-danger" *ngIf="resetForm.touched && passwordInput.invalid">Your password must be at least 8 characters long</span>
        </div>
        <div class="form-group">
          <label for="password">Confirm Password</label>
          <input type="password" class="form-control" id="confirmPassword" name="token" [(ngModel)]="confirmPassword" required #confirmPass="ngModel">
          <span class="text-danger" *ngIf="confirmPass.touched && password != confirmPassword">The password entered does not match</span>
        </div>
        <button type="submit" class="btn btn-primary btn-block" [disabled]="!resetForm.touched && password != confirmPassword">Reset</button>
      </form>
    </ng-container>
  </div>
</div>


