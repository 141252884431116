import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PrintOptions} from '../../common/components/buttons/print-options.helper';
import {IRIP} from '../../models/student/irip.model';
import {Level, ToastService} from '../../services/toast.service';
import {StudentService} from '../../services/student.service';
import {DistrictService} from '../../services/district.service';
import {IRIPIntervention} from '../../models/student/irip.intervention.model';
import {TypesService} from '../../services/types.service';
import {IRIPDto, IRIPInterventionDto} from '../../common/dtos/IRIPDto';
import {StaffSelectComponent} from '../../components/main/staffButton.component';
import {DistrictIRIPConfig} from '../../common/dtos/DistrictIRIPConfig';
import {IripInvComponent} from './irip-intv.component';

@Component({
  selector: 'app-student-irip',
  templateUrl: './student-irip.component.html',
  styleUrls: ['./student-irip.component.scss']
})
export class StudentIripComponent implements OnChanges, AfterViewInit {
  @Input('iripConfig') set iripConfig(value: DistrictIRIPConfig) {
    console.log(value);
    this._config = value;
  }
  get iripConfig(): DistrictIRIPConfig {
    return this._config;
  }
  _config: DistrictIRIPConfig;

  @ViewChild(StaffSelectComponent) teacherComponent!: StaffSelectComponent;
  @ViewChild('newIntv') newIntvComponent!: IripInvComponent;
  @Input() districtId: string;
  @Input() studentId: string;
  @Input() printOptions: PrintOptions;
  @ViewChild('iripForm') form: NgForm;
  @Input('irip') irip: IRIPDto;
  @Output() iripChange: EventEmitter<IRIPDto> = new EventEmitter<IRIP>();
  newIntv: IRIPInterventionDto = {
    end_date: null,
    implementer: '',
    intervention: '',
    how_often: '',
    result: '',
    start_date: null
  };

  @Input() assessmentsNames: any;
  @Input() enabledAssessments: string[];

  merge(configData: string[], iripData: string[]): string[] {
    const ret: string[] = {...configData};
    ret.push(...iripData);
    ret.sort();
    return ret;
  }


  @Output() closeEvent = new EventEmitter<boolean>();

  impl_values: string[];
  intv_values: string[];
  how_often_values: string[];
  newAOG: string;
  result_values: string[];
  grades: string[];
  school_years: string[];

  editIntvIndex: number;

  NotificationTitle = 'IRIP';

  getAssessmentName(key: string): string {
    if (Object.keys(this.assessmentsNames).indexOf(key) === -1) {
      return key.toUpperCase();
    }
    return this.assessmentsNames[key];
  }

  addAOG() {
    if (!this.irip.areas_of_growth) {
      this.irip.areas_of_growth = [];
    }

    this.form.form.markAsDirty();
    this.irip.areas_of_growth.push(this.newAOG);
  }

  delAOG(aog: string) {
    this.form.form.markAsDirty();
    const index = this.irip.areas_of_growth.indexOf(aog);
    this.irip.areas_of_growth.splice(index, 1);
  }

  constructor(
    private studentService: StudentService,
    private districtService: DistrictService,
    private toastService: ToastService
  ) {
    this.irip = new IRIPDto();
    this.newIntv = new IRIPIntervention();
    this.how_often_values = [];
    this.intv_values = [];
    this.impl_values = [];
  }

  ngAfterViewInit(): void {
    this.newIntvComponent.newIntv();
    this.newIntvComponent.intvChange.subscribe(p => {
      this.irip.interventions = this.irip.interventions || [];
      this.irip.interventions.push(this.newIntvComponent.intv);
      this.newIntvComponent.newIntv();
      this.form.form.markAsTouched();
    });
  }

  yearChange(value: string) {
    this.irip.school_year = value;
    this.form.form.markAsDirty();
    this.form.form.markAsTouched();
  }

  gradeChange(value: string) {
    this.irip.grade = value;
    this.form.form.markAsDirty();
    this.form.form.markAsTouched();
  }

  teamChange(value: any) {
    this.irip.team_members = value;
    this.form.form.markAsTouched();
    this.form.form.markAsDirty();
  }

  staffChange(value: any) {
    this.irip.teacher_id = value;
    this.form.form.markAsTouched();
    this.form.form.markAsDirty();
  }

  removeIntv(index: number) {
    this.irip.interventions.splice(index, 1);
    this.form.form.markAsDirty();
  }

  editIntv(index: number) {
    this.editIntvIndex = index;
  }

  addIntv() {
    this.form.form.markAsDirty();

    if (!this.irip.interventions) {
      this.irip.interventions = [];
    }

    this.irip.interventions.push(this.newIntv);
    this.newIntv = new IRIPIntervention();
  }

  getDistrictData() {
    this.districtService.getBasicType(this.districtId, TypesService.Grades).subscribe(r => {
      this.grades = r ? r.map(m => m.name) : [];
    }, err => this.grades = []);

    this.districtService.getSchoolYears(this.districtId).subscribe(r => {
      this.school_years = r ? r.map(m => m.name) : [];
    }, err => this.school_years = []);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.studentId && this.districtId) {
      this.getDistrictData();
    }
  }

  cancel() {
    this.closeEvent.emit(true);
  }

  ngOnInit(): void {
    this.editIntvIndex = -1;
  }

  save(form: NgForm) {
    this.form.form.markAllAsTouched();
    this.teacherComponent.markAsTouched();
    this.teacherComponent.checkValid();
    if (!form.valid) {
      if (this.irip.guardian?.length === 0) {
        this.toastService.AddNotification(
          Level.WARNING,
          this.NotificationTitle,
          'You must provide a parent or guardian to save'
        );
        return;
      }

      if (!this.irip.date || this.irip.date.length === 0) {
        this.toastService.AddNotification(
          Level.WARNING,
          this.NotificationTitle,
          'You must provide a data dialogue date to save'
        );
        return;
      }

      return;
    }

    for (const key in this.irip.assessment_result_obj) {
      let value = false;
      for (const season in this.irip.assessment_result_obj[key]) {
        value = value || this.irip.assessment_result_obj[key][season].length > 0;
      }
      if (!value) {
        delete this.irip.assessment_result_obj[key];
      }
    }

    (this.irip.id === null ?
      this.studentService.postIRIP(this.districtId, this.studentId, this.irip) :
      this.studentService.patchIRIP(this.districtId, this.studentId, this.irip))
      .subscribe(data => {
        this.toastService.AddNotification(
          Level.SUCCESS,
          this.NotificationTitle,
          'Saved IRIP'
        );
        this.closeEvent.emit(false);
      }, err => {
        this.toastService.AddNotification(
          Level.ERROR,
          this.NotificationTitle,
          'Error saving IRIP'
        );
      });
  }
}
