<sortable-table
  [headers]="headers"
  showAdd="true"
  showEdit="true"
  showDelete="true"
  [data]="data"
  (saveCall)="save($event)"
  (delCall)="del($event)"
  [adding-data]="newRow">

</sortable-table>
