import {Component} from "@angular/core";
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Observation} from '../../models/student/observation.model';
import {Router} from '@angular/router';
import {AuthService} from '../../services/authService';

class Login {
  username: string;
  password: string;
}

@Component({
  selector: `LoginPage`,
  templateUrl: 'loginPage.component.html',
})
export class LoginPageComponent {
  public login: Login = new Login();
  onSubmit(form: NgForm) {
    this.http.post<any>(`/api/user/login`, {
      'username': this.login.username,
      'password': this.login.password,
    }).subscribe(p => {
      this.authService.setToken(p.token);
      this.authService.setRefresh(p.refresh);
      this.authService.isLoggedIn.next(true);
      this.router.navigate(['/']);
    }, err => {
      console.log(err);
    });
  }

  forgotPassword() {
    this.router.navigate(['forgotPassword']);
  }
  constructor(private authService: AuthService, private http: HttpClient, private router: Router) {
  }
}
