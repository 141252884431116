import {Observable, throwError as observableThrowError} from 'rxjs';
/**
 * Created by ptdave on 4/27/17.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Staff} from '../models/staff.model';
import {HttpClient} from '@angular/common/http';
import {AuthService} from "./authService";
import {StudentDto} from "../common/dtos/studentDto";
import {environment} from "../../environments/environment";

export interface JWTPayload {
  buildingIds: string[]
  districtId: string
  "districtName": string
  "exp": number,
  "firstname": string
  "googleId": string
  "iat": number
  "iss": string
  "jti": string
  "lastname": string
  "roleIds": string[],
  "roles": string[],
  "sidrAdmin": boolean
  "sub": string
  "title": string
}


@Injectable()
export class UserService implements CanActivate {
  constructor(private http: HttpClient,
              private router: Router,
              private authService: AuthService) {
  }

  gApiSetup: boolean = false;
  authInstance: google.accounts.oauth2.CodeClient;

  async initGoogleAuth(): Promise<void> {
    this.authInstance = await google.accounts.oauth2.initCodeClient({
      client_id: environment.google_client_id,
      ux_mode: 'popup',
      redirect_uri: environment.redirect_uri,
      scope: 'profile email',
      callback: response => {
        this.router.navigate(['/token'], {
          queryParams: {
            code: response.code
          }
        });
      }
    });
    this.gApiSetup = true;
  }

  getClaims() : JWTPayload {
    let jwt = sessionStorage.getItem('jwt');
    if(jwt.length > 0) {
      let base64Url = jwt.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
    return null;
  }

  async authenticate(): Promise<void> {
    if (!this.gApiSetup) {
      await this.initGoogleAuth();
    }

    return new Promise(async () => {
      this.authInstance.requestCode();
    });
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.loggedIn();
  }

  login() {
    window.location.href = window.location.protocol + '//' + window.location.hostname + '/api/user/login';
  }

  loggedIn(): Observable<boolean> {
    return this.authService.isLoggedIn;
  }

  logout() {
    this.authService.isLoggedIn.next(false);
    this.authService.clear();
    this.router.navigate(['/'])
  }


  get(id: string): Observable<Staff> {
    return this.http.get<Staff>('/api/staff/' + id);
  }

  update(id: string, staff: Staff): Observable<Staff> {
    console.log('TODO: update');
    return null;
  }

  getStudents(staffId: string, groupBy?: string): Observable<StudentDto[]> {
    var query = groupBy ? '?groupBy=' + groupBy : '';
    return this.http.get<StudentDto[]>(`/api/staff/${staffId}/students}`);
  }

  getStudentsGroupByBuilding(staffId: string): Observable<Record<string,StudentDto[]>> {
    return this.http.get<Record<string,StudentDto[]>>(`/api/staff/${staffId}/students?groupBy=building`);
  }

  private handleError(error: Response) {
    console.error(error);
    return observableThrowError(error || 'Server error');
  }
}
