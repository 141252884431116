import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DistrictIRIPConfig} from '../../common/dtos/DistrictIRIPConfig';
import {IRIPInterventionDto} from '../../common/dtos/IRIPDto';

@Component({
  selector: 'irip-intv',
  templateUrl: './irip-intv.component.html',
})
export class IripInvComponent {
  randKey: string;
  @Input('iripConfig') _config: DistrictIRIPConfig;
  @Input() intv: IRIPInterventionDto;
  @Output() intvChange = new EventEmitter();

  @Output() deleted = new EventEmitter();
  editing = false;


  temp: IRIPInterventionDto | null = null;

  makeID(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  newIntv() {
    this.intv = new class implements IRIPInterventionDto {
      end_date: Date;
      how_often: string;
      implementer: string;
      intervention: string;
      result: string;
      start_date: Date;
    };
    this.edit();
  }

  save() {
    this.intvChange.emit(this.intv);
    this.intv = {...this.intv};
  }

  edit() {
    this.temp = {...this.intv};
    this.editing = true;
  }

  delete() {
    this.deleted.emit(this.intv);
  }

  cancel() {
    this.intv = {...this.temp};
    this.temp = null;
  }

  constructor() {
    this.randKey = this.makeID(20);
    this.intv = new class implements IRIPInterventionDto {
      end_date: Date;
      how_often: string;
      implementer: string;
      intervention: string;
      result: string;
      start_date: Date;
    };
  }
}
