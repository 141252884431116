import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: `googleAuth`,
  templateUrl: 'googleAuth.component.html',
  styleUrls: ['googleAuth.component.css']
})
export class GoogleAuthComponent implements AfterContentInit {
  gApiSetup = false;
  authInstance: google.accounts.oauth2.CodeClient;

  constructor(private userService: UserService, private router: Router) {

  }

  async ngAfterContentInit() {
    await this.initGoogleAuth();
  }

  async initGoogleAuth(): Promise<void> {
    this.authInstance = await google.accounts.oauth2.initCodeClient({
      client_id: environment.google_client_id,
      ux_mode: 'redirect',
      redirect_uri: environment.redirect_uri,
      scope: 'profile email',
      callback: response => {
        this.router.navigate(['/token'], {
          queryParams: {
            code: response.code
          }
        });
      }
    });

    this.gApiSetup = true;
  }

  async authenticate(): Promise<void> {
    this.userService.authenticate();
  }

}
