import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import {OptionsService} from 'src/app/services/options.service';

import {AuthService} from "../../services/authService";
import {StudentDto} from "../../common/dtos/studentDto";
import {animate, sequence, state, style, transition, trigger} from "@angular/animations";
import {DistrictService} from "../../services/district.service";
import {DistrictContactDto} from "../../common/dtos/districtDto";
import {StudentService} from "../../services/student.service";
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-default',
  templateUrl: './home-default.component.html',
  styleUrls: ['./home-default.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: `translateX(25%)`,
          'opacity': '0'
        }),
        animate('200ms ease-in', style({
          transform: 'translateX(0%)',
          'opacity': '1'
        }))
      ]),
      transition(':leave', [
        animate('50ms ease-in', style({
          transform: `translateY(25%)`,
          'opacity': '0'
        }))
      ]),

    ]),
    trigger('hideImage', [
      state('visible', style({
        height: '100%',
        width: '100%',
        opacity: 1,
        transform:'scale(1)',
        display: 'block'
      })),
      state('hidden', style({
        height: '0%',
        width: '0%',
        opacity: 0,
        transform:'scale(0)',
        display: 'none'
      })),
      transition('* <=> *', [
        animate('500ms')
      ])
    ])
  ]
})
export class HomeDefaultComponent implements OnInit {
  students: Record<string, StudentDto[]>;
  display: string;
  token: string;
  contact: DistrictContactDto;

  constructor(private userService: UserService,
              private authService: AuthService,
              private optionService: OptionsService,
              private studentService: StudentService,
              private router: Router,
              private districtService: DistrictService) {
    this.optionService.setString('home-default-display', 'grid');
  }

  onDisplayChange(value) {
    this.display = value;
    this.optionService.setString('home-default-display', value);
  }

  ngOnInit() {
    this.token = this.authService.getToken();
    this.display = this.optionService.getString('home-default-display', 'grid');
    this.getStudents();
    this.getDistrictContact();
  }

  navigate(student: StudentDto) {
    this.router.navigate(['/student', student.districtId, student.id]);
  }

  getDistrictContact() {
    const claims = this.userService.getClaims();
    if (!claims) {
      this.contact = null;
      return;
    }
    this.districtService.get(claims.districtId).subscribe(r => {
      this.contact = r.contact;
    });
  }

  getStudents() {
    this.userService.getStudentsGroupByBuilding(this.authService.parseJwt().jti).subscribe(r => {
      this.students = r ?? {};

      // Assemble all the students
      let allStudents = [];
      for (const key in this.students) {
        allStudents = allStudents.concat(this.students[key]);
      }
      this.studentService.photos(allStudents.map(s => s.id)).subscribe(r => {
        r = r || {};
        for(const b in this.students) {
          for(const s in this.students[b]) {
            if (r[this.students[b][s].id]) {
              this.students[b][s].photoUrl = r[this.students[b][s].id];
            }
          }
        }
      });
    });
  }

  isEmpty(data: Record<string, StudentDto[]>): boolean {
    if (!data) {
      return true;
    }
    return Object.keys(data).length === 0
  }

  photoError(event: any) {
    event.src = '';
  }

  protected readonly Object = Object;
}
