export interface IRIPInterventionDto {
  intervention: string;
  implementer: string;
  how_often: string;
  start_date: Date;
  end_date: Date;
  result: string;
}

export class AssessmentResultDto {
  fall: string;
  winter: string;
  spring: string;
  constructor() {
    this.fall = '';
    this.winter = '';
    this.spring = '';
  }
}

export class IRIPDto {
  id: string;
  grade: string;
  guardian: string;
  teacher_id: string;
  team_members: string[];
  school_year: string;
  date: string;
  assessment_result: string;
  assessment_result_obj: any;
  other_factors: string;
  strengths: string;
  areas_of_growth: string[];
  learning_goals: string;
  interventions: IRIPInterventionDto[];
  guardian_notification_date: string;
  home_plan_provided: string;
  guardian_collaboration_date: string;
  notes_from_guardian_collaboration: string;
  result_of_intervention: string;

  constructor(assessmentIds?: string[]) {
    this.id = null;
    this.areas_of_growth = [];
    this.assessment_result = '';
    this.date = null;
    this.assessment_result_obj = {};
    if (assessmentIds) {
      assessmentIds.forEach(id => {
        this.assessment_result_obj[id] = new AssessmentResultDto();
      });
    }
    this.grade = '';
    this.guardian = '';
    this.guardian_collaboration_date = null;
    this.guardian_notification_date = null;
    this.home_plan_provided = '';
    this.interventions = [];
    this.learning_goals = '';
    this.notes_from_guardian_collaboration = '';
    this.other_factors = '';
    this.result_of_intervention = '';
    this.school_year = '';
    this.strengths = '';
    this.teacher_id = '';
    this.team_members = [];
  }
}
