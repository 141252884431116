<form #featureForm="ngForm" (ngSubmit)="onSubmit(featureForm)" class="container">
  <div class="form-group">
    <label for="name">Feature   Name</label>
    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="feature.name" required>
  </div>
  <div class="form-group">
    <label for="displayName">Display Name</label>
    <input type="text" class="form-control" id="displayName" name="displayName" [(ngModel)]="feature.displayName" required>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="enabled" name="enabled" [(ngModel)]="feature.enabled">
    <label for="enabled" class="form-check-label">Enabled</label>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="enabled" name="availableToDistricts" [(ngModel)]="feature.availableToDistricts">
    <label for="availableToDistricts" class="form-check-label">Available to Districts</label>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="enabled" name="defaultDistrictValue" [(ngModel)]="feature.availableToDistricts">
    <label for="defaultDistrictValue" class="form-check-label">Default District Value</label>
  </div>
  <button type="submit" class="btn btn-primary">Submit</button>
</form>
