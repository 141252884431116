import {NgModule} from "@angular/core";
import {IntroPageComponent} from "./introPage/introPage.component";
import {LoginPageComponent} from "./loginPage/loginPage.component";
import {PrivacyPageComponent} from "./privacyPage/privacyPage.component";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SIDRCommonModule} from "../common/sidrCommon.module";
import {DemoComponent} from "./demoPage/demo.component";
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    IntroPageComponent,
    LoginPageComponent,
    PrivacyPageComponent,
    DemoComponent
  ],
  imports: [
    RouterModule,
    NgbModule,
    FormsModule,
    SIDRCommonModule,
  ],
  exports: [
    IntroPageComponent,
    LoginPageComponent,
    PrivacyPageComponent,
    DemoComponent
  ],
  providers: []
})
export class UnauthenticatedModule {}
