import { throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { District } from '../models/district/district.model';
import { MailResponse, Message } from '../models/gmail/gmail.models';
import {AuthService} from "./authService";
import {FeatureDto} from '../admin/features/featureDto';

@Injectable()
export class AdminService implements CanActivate {
  private baseRoute = '/api/admin';
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.tokenService.parseJwt().sidrAdmin;
  }

  public getDistrict(id: string): Observable<District> {
    return this.http.get<District>(this.baseRoute + 'district/' + id);
  }

  public getFeatures(): Observable<FeatureDto[]> {
    return this.http.get<FeatureDto[]>(`${this.baseRoute}/features`);
  }

  public getFeature(id: string): Observable<FeatureDto> {
    return this.http.get<FeatureDto>(`${this.baseRoute}/feature/${id}`);
  }

  public postFeature(feature: FeatureDto): Observable<FeatureDto> {
    return this.http.post<FeatureDto>(`${this.baseRoute}/feature`, feature);
  }

  public updateFeature(feature: FeatureDto): Observable<FeatureDto> {
    return this.http.post<FeatureDto>(`${this.baseRoute}/feature/${feature.id}`, feature);
  }

  public getDistricts(): Observable<District[]> {
    return this.http.get<District[]>(`${this.baseRoute}/districts`);
  }

  protected handleError(error: Response) {
    return observableThrowError(error || 'Server error');
  }

  constructor(private http: HttpClient, private userService: UserService, private tokenService: AuthService) {}
}
