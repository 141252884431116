<app-student-module [PrintOptions]="printOptions" ModuleName="IRIP"
                    (New)="open(null,null)"
                    (Save)="openIrip.save(openIrip?.form)"
                    [SaveHidden]="selectedIRIP === null"
                    [SaveDisabled]="(openIrip?.form.form.invalid || openIrip?.teacherComponent?.invalid) || !(openIrip?.form.form.touched || openIrip?.teacherComponent?.touched)"
                    (Cancel)="openIrip.cancel()"
                    [CancelHidden]="selectedIRIP === null">
  <div class="table-responsive" [hidden]="selectedIRIP !== null">
    <table class="table">
      <thead>
      <tr>
        <td>Grade</td>
        <td>School Year</td>
        <td>Action</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let irip of irips; index as i">
        <td>{{irip?.grade}}</td>
        <td>{{irip?.school_year}}</td>
        <td>
          <span class="mr-auto"></span>
          <button-more (btnClick)="open(i, irip)" btnClass="pull-right"></button-more>
          <button (click)="print(irip.id)" class="btn ml-1 btn-primary">
            <i class="fa fa-print" aria-hidden="true"></i>
          </button>
          <button-delete btnClass="ml-1" (btnClick)="delete(irip.id)" [roles]="['can_admin']"></button-delete>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-print-none d-flex flex-row" *ngIf="selectedIRIP !== null">
    <app-student-irip #openIrip class="col" [districtId]="districtId" [studentId]="studentId" [(irip)]="selectedIRIP" (closeEvent)="close($event)" [assessmentsNames]="assessments" [iripConfig]="iripConfig"></app-student-irip>
  </div>
  <app-student-irip *ngFor="let irip of irips" class="d-none d-print-block" [studentId]="studentId" [irip]="irip" [assessmentsNames]="assessments"  [iripConfig]="iripConfig"></app-student-irip>
</app-student-module>
