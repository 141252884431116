import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/authService';
import {TitleService} from '../../services/title.service';
import {HttpClient} from '@angular/common/http';
import {Level, ToastService} from '../../services/toast.service';

@Component({
  selector: 'forgotpassword',
  templateUrl: 'forgotpassword.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  token: string;
  email: string;
  password: string;
  confirmPassword: string;

  submitted = false;

  ngOnInit(): void {
    this.activeRoute.queryParamMap.subscribe(params => {
      console.log(params);
      if (params.has('token') && params.has('email')) {
        this.token = params.get('token');
        this.email = params.get('email');
      }
    });
  }

  requestForgotPassword(): void {
    this.http.post(`/api/user/forgotPassword?email=${this.email}`, null)
      .subscribe(obs => {
        this.submitted = true;
      });
  }

  changePassword(): void {
    this.http.post(`/api/user/validateForgotPassword`, {
      email: this.email,
      token: this.token,
      password: this.password
    }).subscribe(obs => {
      this.toast.AddNotification(Level.SUCCESS, 'Forgot Password', 'Password changed successfully.');
      this.router.navigate(['/login']);
    }, err => {
      this.toast.AddNotification(Level.ERROR, 'Forgot Password', 'Failed to change password');
    });
  }

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private title: TitleService,
    private toast: ToastService,
    private authService: AuthService,
    private http: HttpClient) {
    this.token = '';
    this.email = '';
    this.password = '';
    this.confirmPassword = '';

    this.title.setTitle('Forgot Password');
  }
}
