import {Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';

export enum SortDirection {
  None = 0,
  Ascending = 1,
  Descending = 2
}

export enum DataType {
  String = 0,
  Date = 1,
  Number = 2,
  Paragraph = 3,
  Boolean = 4,
  Select = 5
}

export class SortableTableOptions {
  sortOrder?: SortDirection;
  hide?: boolean;
  dateFormat?: string;
  dropdownValues?: string[];
}

export class Header {
  name: string;
  value: string;
  filterable: boolean;
  dataType: DataType;
  sortOrder: SortDirection;
  hide: boolean;
  dateFormat?: string;
  dropdownValues: string[];
  defaultValue: any;

  constructor(
    name: string,
    value: string,
    filterable: boolean,
    dataType?: DataType,
    options?: SortableTableOptions
  ) {
    this.name = name;
    this.value = value;
    this.filterable = filterable;

    if (!dataType) {
      this.dataType = DataType.String;
    } else {
      this.dataType = dataType;
    }

    if (options) {
      if (options.dropdownValues) {
        this.dropdownValues = options.dropdownValues;
      }
      if (options.dateFormat) {
        this.dateFormat = options.dateFormat;
      }
      if (options.hide) {
        this.hide = options.hide;
      }
      if (options.sortOrder) {
        this.sortOrder = options.sortOrder;
      }
    } else {
      this.sortOrder = SortDirection.None;
      this.hide = false;
      this.dateFormat = 'shortDate';
      this.dropdownValues = [];
    }
  }
}

@Component({
  selector: `sortable-table`,
  styles: [
    `
      .row-disabled {
        pointer-events: none;
        opacity: 0.2;
        -webkit-transition: opacity 0.25s ease-in-out;
        -moz-transition: opacity 0.25s ease-in-out;
        -ms-transition: opacity 0.25s ease-in-out;
        -o-transition: opacity 0.25s ease-in-out;
      }
      .options {
        max-width: 15vw;
        width: 1vw;
      }
      .tr-form {
        display: table-row;
      }
    `
  ],
  templateUrl: 'sortableTable.html'
})
export class SortableTableComponent {
  @Output() delCall = new EventEmitter();
  @Output() editCall = new EventEmitter();
  @Output() moreCall = new EventEmitter();
  @Output() saveCall = new EventEmitter();
  @Output() addCall = new EventEmitter();

  @Input() showAdd: boolean;
  @Input() showDelete: boolean;
  @Input() showEdit: boolean;
  @Input() showMore: boolean;
  @Input() showCount: boolean;
  @Input() hideHeader: boolean;
  @Input() headers: Header[];
  @Input() filter: any;
  @Input() sorting: number;
  @Input() defaultSort: string;
  @Input() colorize: Function;

  sortedHeader: Header;
  editting: number;
  @Input('editing-data') duped: any;
  @Output('editing-data') dupedChanged = new EventEmitter();
  @Input('adding-data') newValue: any;
  @Output('adding-data') newValueChanged = new EventEmitter();

  omittedValues: any;

  @ViewChild('addForm')
  public addForm: NgForm;
  @ViewChild('editForm')
  public editForm: NgForm;

  constructor() {
    this.hideHeader = false;
    this.sortedHeader = null;
    this._data = [];
    this.sorting = -1;
    this.filter = {};
    this.headers = [];
    this.defaultSort = '';
    this.colorize = this.defColorize;
    this.showDelete = false;
    this.showEdit = false;
    this.showMore = false;
    this.editting = -1;
    this.newValue = {};
  }

  get showHeader(): boolean {
    return !this.hideHeader && this.headers.length > 0;
  }

  private _data: any[];

  get data() {
    return this._data;
  }

  @Input()
  set data(value: any[]) {
    this._data = value || [];
    this.sort();
  }

  toggleBoolean(obj: any, key: string) {
    if (!obj[key]) {
      obj[key] = false;
    }
    obj[key] = !obj[key];
  }

  cancel() {
    this.editting = -1;
    this.duped = null;
    this.newValue = {};
  }

  isOmitted(key: string, value: any): boolean {
    if (!this.omittedValues) {
      return false;
    }

    if (!this.omittedValues[key]) {
      return false;
    }

    return this.omittedValues[key].indexOf(value) !== -1;
  }

  toggleOmit(key: string, value: any) {
    if (!this.omittedValues) {
      this.omittedValues = {};
    }

    if (!this.omittedValues[key]) {
      this.omittedValues[key] = [];
    }

    if (this.omittedValues[key].indexOf(value) === -1) {
      this.omittedValues[key].push(value);
    } else {
      this.omittedValues[key].splice(this.omittedValues[key].indexOf(value), 1);
    }
  }

  add(data: any) {
    this.addCall.emit(data);
  }

  editRow(n: number): boolean {
    return this.editting === n;
  }

  disableRow(n: number): boolean {
    return this.editting !== n && this.editting > -1;
  }

  dupe(d: any): any {
    return JSON.parse(JSON.stringify(d));
  }

  save(d: any): any {
    this.saveCall.emit(d);
    this.editting = -1;
  }

  edit(d: any, index: number) {
    this.duped = this.dupe(d);
    this.editting = index;
  }

  del(d: any) {
    this.delCall.emit(d);
  }

  clear() {
    this.newValue = {};
  }

  more(d: any) {
    this.moreCall.emit(d);
  }

  color(d: any): string {
    return this.colorize(d);
  }

  showOptions(): boolean {
    return this.showMore || this.showEdit || this.showDelete;
  }

  sort() {
    if (this.sorting !== -1) {
      this._data.sort(
        (a: any, b: any): number => {
          if (this.sortedHeader.sortOrder === SortDirection.Ascending) {
            if (a[this.sortedHeader.value] > b[this.sortedHeader.value]) {
              return 1;
            }
            if (a[this.sortedHeader.value] < b[this.sortedHeader.value]) {
              return -1;
            }
            return 0;
          }
          if (this.sortedHeader.sortOrder === SortDirection.Descending) {
            if (a[this.sortedHeader.value] < b[this.sortedHeader.value]) {
              return 1;
            }
            if (a[this.sortedHeader.value] > b[this.sortedHeader.value]) {
              return -1;
            }
            return 0;
          }
          return 0;
        }
      );
    } else if (this.defaultSort) {
      if (!this._data || this._data.length > 0) {
        this._data.sort((a: any, b: any) => {
          if (a[this.defaultSort] < b[this.defaultSort]) {
            return 1;
          }
          if (a[this.defaultSort] > b[this.defaultSort]) {
            return -1;
          }
          return 0;
        });
      }
    }
  }

  sortHeader(index: number) {
    // NO SORTING WHILE EDITTING
    if (this.editting !== -1) {
      return;
    }

    if (this.sorting !== index) {
      this.sorting = index;
      this.sortedHeader = this.headers[index];
      if (this.headers[index].sortOrder === 0) {
        this.headers[index].sortOrder++;
      }
    } else {
      this.headers[index].sortOrder++;
      if (this.headers[index].sortOrder > 2) {
        this.headers[index].sortOrder = 0;
        this.sorting = -1;
        this.sortedHeader = null;
      }
    }
    this.sort();
  }

  @HostListener('window:beforeunload', ['$event'])
  checkDirty($e) {
    if (
      this.addForm &&
      this.addForm.form &&
      !this.addForm.form.pristine &&
      this.addForm.form.dirty
    ) {
      $e.returnValue = 'Lose unsaved changes?';
    }
  }

  private defColorize(): string {
    return '';
  }
}
