import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { PrintOptions } from '../../common/components/buttons/print-options.helper';

@Component({
  selector: `student-print`,
  templateUrl: 'student.printdialog.component.html',
})
export class StudentPrintDialogComponent {
  @Input()
  options: PrintOptions;

  active: any;

  open(content) {
    this.modalService
      .open(content, {backdrop: 'static'}).result
      .then((result) => {
        if (result === 'print') {
          window.print();
        }
      });
  }

  constructor(private modalService: NgbModal) {
  }
}
