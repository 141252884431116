import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {PrintOptions, PrintTab, PrintTabOption} from '../../common/components/buttons/print-options.helper';
import {StudentAppropriateinstructionComponent} from '../appropriateInstructions/student.appropriateinstruction.component';
import {StudentBehaviorInterventionsComponent} from '../behaviorInterventions/student.behaviorinterventions.component';
import {StudentMeetingsComponent} from '../meeting/student.meetings.component';
import {StudentObservationsComponent} from '../observations/student.observations.component';
import {StudentOtherFactorsComponent} from '../otherFactors/student.otherfactors.component';
import {StudentCommunicationComponent} from '../communication/student.communication.component';
import {StudentIntvLogComponent} from '../interventionLog/student.intvlog.component';
import {StudentAchievementsComponent} from '../achievements/student-achievements.component';
import {Student} from '../../models/student/student.model';
import {StudentRunningRecordComponent} from '../runningRecord/student-runningrecord.component';
import {StudentService} from '../../services/student.service';
import {TitleService} from '../../services/title.service';

@Component({
  selector: 'app-student-view',
  templateUrl: './studentPage.component.html',
  styleUrls: ['./studentPage.component.scss']
})
export class StudentPageComponent implements OnInit {
  studentId: string;
  districtId: string;
  loading: boolean;
  student: Student;
  printOptions: PrintOptions;
  selected: string;
  subselect: string;
  showPhoto = false;

  @ViewChild('achievements') public Achievements: StudentAchievementsComponent;
  @ViewChild(StudentAppropriateinstructionComponent) public ApprInst;
  @ViewChild(StudentCommunicationComponent) public Communication;
  @ViewChild('runningrecord') public RunningRecord: StudentRunningRecordComponent;
  @ViewChild('bhvrintv') public InterventionBehaviors: StudentBehaviorInterventionsComponent;
  @ViewChild(StudentIntvLogComponent) public InterventionLogs;
  @ViewChild(StudentMeetingsComponent) public Meetings;
  @ViewChild(StudentObservationsComponent) public Observations;
  @ViewChild('otherfactors') public OtherFactors: StudentOtherFactorsComponent;


  constructor(private route: ActivatedRoute, private router: Router, public studentService: StudentService, public domSanit: DomSanitizer, private titleService: TitleService) {
    this.loading = false;
    this.student = new Student();
    this.printOptions = new PrintOptions();
    this.studentId = '';
    this.districtId = '';

    const displayTab = new PrintTab('Print Options');
    displayTab.options.push(new PrintTabOption('Section Headers', 'display.headers', 'checkbox', true));
    displayTab.options.push(new PrintTabOption('Student Information', 'display.info', 'checkbox', true));
    displayTab.options.push(new PrintTabOption('Student Tab', 'display.student', 'checkbox', true));
    displayTab.options.push(new PrintTabOption('Staff Tab', 'display.staff', 'checkbox', true));
    displayTab.options.push(new PrintTabOption('Meetings Tab', 'display.meetings', 'checkbox', true));

    const studentTab = new PrintTab('Student Tab', 'display.student');
    studentTab.options.push(new PrintTabOption('Achievements', 'data.achievements', 'checkbox', true));
    studentTab.options.push(new PrintTabOption('Assessments', 'data.assessments', 'checkbox', true));
    studentTab.options.push(new PrintTabOption('Attendance', 'data.attendance', 'checkbox', true));
    studentTab.options.push(new PrintTabOption('Attendance by Period', 'data.attendanceperiod', 'checkbox', true));
    studentTab.options.push(new PrintTabOption('Historical Behaviors', 'data.behaviors', 'checkbox', true));
    studentTab.options.push(new PrintTabOption('Other Factors', 'data.otherfactors', 'checkbox', true));

    const staffTab = new PrintTab('Staff Tab', 'display.staff');
    staffTab.options.push(new PrintTabOption('Communication', 'staff.communication', 'checkbox', true));
    staffTab.options.push(new PrintTabOption('Running Record', 'staff.runningrecord', 'checkbox', true));
    staffTab.options.push(new PrintTabOption('Behavior Interventions', 'staff.bhvrintv', 'checkbox', true));
    staffTab.options.push(new PrintTabOption('Intervention Logs', 'staff.intvlog', 'checkbox', true));
    staffTab.options.push(new PrintTabOption('IRIP', 'staff.irip', 'checkbox', true));
    staffTab.options.push(new PrintTabOption('Observational Data', 'staff.observations', 'checkbox', true));
    // staffTab.options.push(new PrintTabOption('Appropriate Instruction', 'staff.appr_inst', 'checkbox', true));



    this.printOptions.tabs.push(displayTab);
    this.printOptions.tabs.push(studentTab);
    this.printOptions.tabs.push(staffTab);
  }

  get token(): string {
    return sessionStorage.getItem('jwt');
  }

  photoError(event) {
    this.showPhoto = false;
  }

  select(select: string, subselect = '') {
    this.selected = select;
    this.subselect = subselect;
  }

  hide(select: string, subselect = ''): boolean {
    if (select === 'data' || select === 'logs') {
      return subselect !== this.subselect;
    }
    return select !== this.selected;
  }

  loadStudent() {
    this.loading = true;
    this.studentService.get(this.districtId, this.studentId).subscribe(resp => {
      this.titleService.setTitle(`${resp.firstName} ${resp.lastName}`);
      this.select('Information');
      this.student = resp;
      this.showPhoto = true;
      this.loading = false;
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.showPhoto = false;
      this.studentId = params['student_id'];
      this.districtId = params['district_id'];
      this.loadStudent();
    });
  }
}
