import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {District} from '../../models/district/district.model';
import {ActivatedRoute} from '@angular/router';
import {NgModel} from '@angular/forms';
import {FeatureDto} from './featureDto';

@Component({
  selector: 'sidr-admin-features',
  templateUrl: 'adminFeatures.component.html',
})
export class AdminFeaturesComponent implements OnInit {
  features: Array<FeatureDto>;

  constructor(
    private adminService: AdminService,
    private router: ActivatedRoute
  ) {
    this.features = [];
  }

  ngOnInit() {
    this.adminService.getFeatures().subscribe(r => this.features = r || []);
  }
}
