import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { HttpRequest, HttpResponse, HttpEvent, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

export interface UploadRequestOptions {
  url: string;
  headers: HttpHeaders;
}

export interface FileWrapper {
  file: File;
  name: string;
  mime: string;
  upload?: Observable<any>;
  subscription?: Subscription;
  progress?: number;
  isUploading?: boolean;
  isUploaded?: boolean;
  isFinished?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  index?: number;
}

@Directive({
  selector: '[uploader]',
  exportAs: 'SIDRUploader'
})
export class SIDRUploaderDirective {
  @Output() private filesChangedEmitter: EventEmitter<File[]> = new EventEmitter<File[]>();

  public uploadOptions: UploadRequestOptions;
  queue: any[];
  @Input('allowedMimeTypes') allowedMimeTypes: string[];
  private _isDraggedOver: boolean;

  @HostListener ('drop', ['$event']) private _onDrop(event: DragEvent | any) {

    if (!event.dataTransfer) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.change.detach();

    const files: FileWrapper[] = [];
    const dataTransfer = event.dataTransfer;
    let dataTransferFiles = event.dataTransfer.files;

    this.allowedMimeTypes = this.allowedMimeTypes ?? [];
    if (this.allowedMimeTypes.length > 0) {
      dataTransferFiles = dataTransferFiles.filter((f: File) => this.allowedMimeTypes.indexOf(f.type) > -1);
    }
    this.filesChangedEmitter.emit(dataTransferFiles);

    this._isDraggedOver = false;
    this.change.reattach();
  }
  @HostListener('dragover', ['$event']) private _onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this._isDraggedOver = true;
  }
  @HostListener('dragleave', ['$event']) private _onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this._isDraggedOver = false;
  }

  get isDraggedOver(): boolean {
    return this._isDraggedOver;
  }

  appendFile(file: any) {
    if (!this.queue) {
      this.queue = [];
    }


    this.queue.push(file);
    // this.markIndex();
    this.toNext();
  }

  removeFile(file: any) {
    const index = this.queue.findIndex(file);
    if (index < 0) {
      return;
    }

    if (file.subscription) {
      file.subscription.unsubscribe();
    }
    this.queue.splice(index, 1);
    // this.markIndex();
  }

  toNext(lastFile?: FileWrapper) {
    let index = 0;
    if (lastFile) {
      index = lastFile.index;
    }

    for (index; index < this.queue.length; index++) {
      if (!this.queue[index].isFinished && !this.queue[index].isUploading && !this.queue[index].isUploaded) {
        // Start this file
        this.upload(this.queue[index]);
      } else if (this.queue[index].isUploading && this.queue[index].isUploaded) {
        // Quit, because we are already uploading. 1 at a time
        return;
      }
    }
  }

  // markIndex() {
  //   for (let i = 0; i < this.queue.length; i++) {
  //     this.queue[i].index = i;
  //   }
  // }

  upload(file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    file.upload = Observable.create(o => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          file.isFinished = true;
          file.isUploaded = true;
          file.isUploading = false;
          if (xhr.status === 200) {
            file.isSuccess = true;
            file.isError = false;
            o.next(xhr.response);
            o.complete();
          } else {
            file.isError = true;
            file.isSuccess = false;
            o.error(xhr.response);
            o.complete();
          }
          this.toNext(file);
        }
        this.change.markForCheck();
      };

      xhr.upload.onprogress = (event) => {
        file.progress = Math.round(event.loaded / event.total * 100);

        file.isUploading = file.progress < 100;
        file.isUploaded = file.progress === 100;
        this.change.markForCheck();
      };

      xhr.open('POST', this.uploadOptions.url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + window.sessionStorage.getItem('jwt'));
      xhr.send(formData);
    });

    file.upload.subscribe(r => {

    });

  }

  constructor(private el: ElementRef, private http: HttpClient, private change: ChangeDetectorRef) {
    this.allowedMimeTypes = [];
    this._isDraggedOver = false;
    this.queue = [];
    this.uploadOptions = {
      url: '',
      headers: new HttpHeaders(),
    };
  }
}
