<div class="d-flex">
  <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical" [(activeId)]="active">
    <li ngbNavItem="aog">
      <a ngbNavLink>Areas of Growth</a>
      <ng-template ngbNavContent>
        <h4>Areas of Growth</h4>
        <ul class="list-group">
          <li class="list-group-item d-flex flex-row" *ngFor="let aog of areas_of_growth">
            <span class="mr-auto">{{ aog.name }}</span>
            <button-delete (btnClick)="deleteAOG(aog)" btnClass="btn-sm"></button-delete>
          </li>
          <li class="list-group-item d-flex flex-row">
            <div class="input-group">
              <input type="text" minlength="3" name="newAOG" [(ngModel)]="newAOG.name" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-success" (btnClick)="addAOG(newAOG)">
                  <span class="fa fa-plus">&nbsp;</span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
    </li>
    <li ngbNavItem="how_often">
      <a ngbNavLink>How Often</a>
      <ng-template ngbNavContent>
        <h4>How Often</h4>
        <ul class="list-group">
          <li class="list-group-item d-flex flex-row" *ngFor="let ho of how_often">
            <span class="mr-auto">{{ ho.name }}</span>
            <button-delete (btnClick)="deleteHowOften(ho)" btnClass="btn-sm"></button-delete>
          </li>
          <li class="list-group-item d-flex flex-row">
            <div class="input-group">
              <input type="text" minlength="3" name="newHO" [(ngModel)]="newHowOften.name" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-success" (btnClick)="addHowOften(newHowOften)">
                  <span class="fa fa-plus">&nbsp;</span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
    </li>
    <li ngbNavItem="results_assessments">
      <a ngbNavLink>ELA Assessments</a>
      <ng-template ngbNavContent>
        <h4>ELA Assessments</h4>
        <ul class="list-group">
          <button class="list-group-item list-group-item-action assessment" *ngFor="let i of assessments" (click)="toggleAssessment(i)" [disabled]="i.processing">
            <span class="fa" [ngClass]="{'fa-check-square-o':i.iripEnabled, 'fa-square-o': !i.iripEnabled}">&nbsp;</span>
            {{ i.name }}
          </button>
          <li class="list-group-item d-flex flex-row">
            <div class="input-group">
              <input type="text" minlength="3" name="newResult" [(ngModel)]="newResult.name" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-success" (btnClick)="addResult(newResult)">
                  <span class="fa fa-plus">&nbsp;</span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
    </li>
    <li ngbNavItem="intervention">
      <a ngbNavLink>Interventions</a>
      <ng-template ngbNavContent>
        <h4>Interventions</h4>
        <ul class="list-group">
          <li class="list-group-item d-flex flex-row" *ngFor="let i of intervention">
            <span class="mr-auto">{{ i.name }}</span>
            <button-delete (btnClick)="deleteIntervention(i)" btnClass="btn-sm"></button-delete>
          </li>
          <li class="list-group-item d-flex flex-row">
            <div class="input-group">
              <input type="text" minlength="3" name="newIntervention" [(ngModel)]="newIntervention.name"
                     class="form-control">
              <div class="input-group-append">
                <button class="btn btn-success" (btnClick)="addIntervention(newIntervention)">
                  <span class="fa fa-plus">&nbsp;</span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
    </li>
    <li ngbNavItem="implementers">
      <a ngbNavLink>Implementers</a>
      <ng-template ngbNavContent>
        <h4>Implementers</h4>
        <ul class="list-group">
          <li class="list-group-item d-flex flex-row" *ngFor="let i of implementer">
            <span class="mr-auto">{{ i.name }}</span>
            <button-delete (btnClick)="deleteImplementer(i)" btnClass="btn-sm"></button-delete>
          </li>
          <li class="list-group-item d-flex flex-row">
            <div class="input-group">
              <input type="text" minlength="3" name="newImplementer" [(ngModel)]="newImplementer.name"
                     class="form-control">
              <div class="input-group-append">
                <button class="btn btn-success" (btnClick)="addImplementer(newImplementer)">
                  <span class="fa fa-plus">&nbsp;</span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
    </li>
    <li ngbNavItem="results">
      <a ngbNavLink>Results</a>
      <ng-template ngbNavContent>
        <h4>Intervention Results</h4>
        <ul class="list-group">
          <li class="list-group-item d-flex flex-row" *ngFor="let i of results">
            <span class="mr-auto">{{ i.name }}</span>
            <button-delete (btnClick)="deleteResult(i)" btnClass="btn-sm"></button-delete>
          </li>
          <li class="list-group-item d-flex flex-row">
            <div class="input-group">
              <input type="text" minlength="3" name="newResult" [(ngModel)]="newResult.name" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-success" (btnClick)="addResult(newResult)">
                  <span class="fa fa-plus">&nbsp;</span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="ml-4 mt-2 flex-grow-1"></div>
</div>
