import {Component, Input, OnInit} from '@angular/core';
import {DistrictService, DistrictType} from '../../../services/district.service';

import {ActivatedRoute} from '@angular/router';
import {DistrictAssessmentDto} from '../../../common/dtos/districtAssessmentDto';
import {forkJoin} from 'rxjs';

class AssessmentContainer {
  id: string;
  iripEnabled: boolean;
  districtEnabled: boolean;
  name: string;
  processing: boolean;
}

@Component({
  selector: `module-irip`,
  templateUrl: 'moduleIRIP.component.html',
  styleUrls: ['moduleIRIP.component.scss']
})
export class ModuleIRIPComponent implements OnInit {
  @Input() districtId: string;
  areas_of_growth: DistrictType[];
  intervention: DistrictType[];
  implementer: DistrictType[];
  how_often: DistrictType[];
  results: DistrictType[];
  assessments: AssessmentContainer[];
  sessions: DistrictType[];

  active: string;

  newAOG: DistrictType;
  newIntervention: DistrictType;
  newImplementer: DistrictType;
  newHowOften: DistrictType;
  newResult: DistrictType;

  addAOG(value: DistrictType) {
    this.districtService.postIRIPAreasOfGrowth(this.districtId, value).subscribe(r => {
      this.areas_of_growth.push(r);
    });
  }

  deleteAOG(value: DistrictType) {
    this.districtService.deleteIRIPAreasOfGrowth(this.districtId, value).subscribe(r => {
      const index = this.areas_of_growth.indexOf(value);
      if (index > -1) {
        this.areas_of_growth.splice(index, 1);
      }
    });
  }

  addIntervention(value: DistrictType) {
    this.districtService.postIRIPInterventions(this.districtId, value).subscribe(r => {
      this.intervention.push(r);
    });
  }

  deleteIntervention(value: DistrictType) {
    this.districtService.deleteIRIPInterventions(this.districtId, value).subscribe(r => {
      const index = this.intervention.indexOf(value);
      if (index > -1) {
        this.intervention.splice(index, 1);
      }
    });
  }

  addImplementer(value: DistrictType) {
    this.districtService.postIRIPImplementer(this.districtId, value).subscribe(r => {
      this.implementer.push(value);
    });
  }

  deleteImplementer(value: DistrictType) {
    this.districtService.deleteIRIPImplementer(this.districtId, value).subscribe(r => {
      const index = this.implementer.indexOf(value);
      if (index > -1) {
        this.implementer.splice(index, 1);
      }
    });
  }

  addHowOften(value: DistrictType) {
    this.districtService.postIRIPHowOften(this.districtId, value).subscribe(r => {
      this.how_often.push(r);
    });
  }

  deleteHowOften(value: DistrictType) {
    this.districtService.deleteIRIPHowOften(this.districtId, value).subscribe(r => {
      const index = this.how_often.indexOf(value);
      if (index > -1) {
        this.how_often.splice(index, 1);
      }
    });
  }

  addResult(value: DistrictType) {
    this.districtService.postIRIPResults(this.districtId, value).subscribe(r => {
      this.results.push(r);
    });
  }

  deleteResult(value: DistrictType) {
    this.districtService.deleteIRIPResults(this.districtId, value).subscribe(r => {
      const index = this.results.indexOf(value);
      if (index > -1) {
        this.results.splice(index, 1);
      }
    });
  }

  toggleAssessment(assessment: AssessmentContainer) {
    assessment.processing = true;
    this.districtService.setIRIPConfigAssessment(this.districtId,assessment.id,!assessment.iripEnabled).subscribe(r => {
      assessment.processing = false;
      assessment.iripEnabled = !assessment.iripEnabled;
    }, err => {
      assessment.processing = false;
    })
  }

  getAssessmentData() {
    forkJoin({
      iripConfig: this.districtService.getDistrictIRIPConfig(this.districtId),
      enabledAssessments: this.districtService.getAssessments(this.districtId, 'ELA', true)
    })
      .subscribe(({iripConfig, enabledAssessments}) => {
        const tmp = (iripConfig.assessments || {});
        this.assessments = [];
        const kvp: Map<string,AssessmentContainer> = new Map<string,AssessmentContainer>();

        for (const key in tmp) {
          kvp.set(key, {
            id: key,
            iripEnabled: true,
            districtEnabled: true,
            name: tmp[key],
            processing: false,
          });
        }
enabled:
        for (const a of enabledAssessments) {
          if (!kvp.has(a.id)) {
            kvp.set(a.id, {
              id: a.id,
              iripEnabled: false,
              districtEnabled: true,
              name: a.name,
              processing: false,
            });
          }
        }

        this.assessments.push(...Array.from(kvp.values()));
      });
  }

  getData() {
    this.getAssessmentData();
    this.districtService.getIRIPAreasOfGrowth(this.districtId).subscribe(r => {
      this.areas_of_growth = r || [];
    }, () => {
      this.areas_of_growth = [];
    });

    this.districtService.getIRIPInterventions(this.districtId).subscribe(r => {
      this.intervention = r || [];
    }, () => {
      this.intervention = [];
    });

    this.districtService.getIRIPImplementer(this.districtId).subscribe(r => {
      this.implementer = r || [];
    }, () => {
      this.implementer = [];
    });

    this.districtService.getIRIPHowOften(this.districtId).subscribe(r => {
      this.how_often = r || [];
    }, () => {
      this.how_often = [];
    });

    this.districtService.getIRIPResults(this.districtId).subscribe(r => {
      this.results = r || [];
    }, () => {
      this.results = [];
    });
  }

  ngOnInit(): void {
    this.activeRoute.parent.parent.params.subscribe(p => {
      if (!p['districtId']) {
        return;
      }
      this.districtId = p['districtId'];
      this.getData();
    });
  }

  constructor(private districtService: DistrictService, private activeRoute: ActivatedRoute) {
    this.newAOG = new DistrictType();
    this.newHowOften = new DistrictType();
    this.newImplementer = new DistrictType();
    this.newIntervention = new DistrictType();
    this.newResult = new DistrictType();
  }
}
