<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Print Options</h4>
    <button class="close" aria-label="Close" (click)="d('cancel')">
      <i class="fa fa-window-close" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li *ngFor="let t of options.tabs" [ngbNavItem]="t.name">
        <a ngbNavLink>{{t.name}}</a>
        <ng-template ngbNavContent>
          <ul class="list-group">
            <li *ngFor="let o of t.options" class="list-group-item">
              <label class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [(ngModel)]="o.value">
                <span class="custom-control-label">{{ o.name }}</span>
              </label>
            </li>
          </ul>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <div class="modal-footer">
    <span class="mr-auto"></span>
    <button class="btn btn-secondary" aria-label="Print" (click)="c('print')">
      <i class="fa fa-print" aria-hidden="true"></i>
      Print
    </button>
    <button class="btn btn-secondary" aria-label="Print" (click)="c('')">
      <i class="fa fa-window-close" aria-hidden="true"></i>
      Cancel
    </button>
  </div>
</ng-template>
<button class="btn btn-primary" (click)="open(content)">
  <i class="fa fa-print" aria-hidden="true"></i>
</button>
