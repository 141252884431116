import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {PrintOptions} from '../../common/components/buttons/print-options.helper';
import {IRIP} from '../../models/student/irip.model';
import {Level, ToastService} from '../../services/toast.service';
import {StudentService} from '../../services/student.service';
import {IRIPDto} from '../../common/dtos/IRIPDto';
import {DistrictService} from '../../services/district.service';
import {DistrictIRIPConfig} from '../../common/dtos/DistrictIRIPConfig';
import {StudentIripComponent} from './student-irip.component';

@Component({
  selector: 'app-student-irip-list',
  templateUrl: './student-irip-list.component.html',
  styleUrls: ['./student-irip-list.component.scss']
})
export class StudentIripListComponent implements OnChanges {
  irips: IRIPDto[];
  @Input() districtId: string;
  @Input() studentId: string;
  @Input() printOptions: PrintOptions;
  assessments: any;

  @ViewChild('openIrip') openIrip: StudentIripComponent;

  iripConfig: DistrictIRIPConfig;

  notificationTitle = 'IRIP';
  selectedIRIP: IRIPDto = null;

  isDirty: boolean;
  isTouched: boolean;

  constructor(
    private studentService: StudentService,
    private districtService: DistrictService,
    private toastService: ToastService) {
    this.assessments = {};
  }

  ngOnChanges() {
    if (this.districtId && this.studentId) {
      this.getData();
    }
  }

  isValidObjectId(str) {
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;
    return objectIdPattern.test(str);
  }

  getData() {
    this.districtService.getDistrictIRIPConfig(this.districtId).subscribe(r => {
      this.iripConfig = r;
      this.assessments = this.iripConfig.assessments;
    });
    this.studentService.getIRIPs(this.districtId, this.studentId).subscribe(r => {
      this.irips = r || [];

      const objectIdKeys = new Set<string>();
      this.irips.forEach(irip => {
        for (const key of Object.keys(irip.assessment_result_obj)) {
          if (this.isValidObjectId(key)) {
            objectIdKeys.add(key);
          } else {
            this.assessments[key] = key.toUpperCase();
          }
        }
      });


      console.log(objectIdKeys);
      if (objectIdKeys.size > 0) {
        this.districtService.getAssessments(this.districtId, null, null, Array.from(objectIdKeys)).subscribe(r => {
          r.forEach(assessment => {
            this.assessments[assessment.id] = assessment.name.toUpperCase();
          });
        });
      }


    }, err => {
      this.toastService.AddNotification(Level.ERROR, this.notificationTitle, err.error);
    });
  }

  open(index: number, irip: IRIP) {
    let assessmentIds: string[] = Object.keys(this.iripConfig.assessments);
    if (irip === null) {
      this.selectedIRIP = new IRIPDto(assessmentIds);
    } else {
      this.selectedIRIP = {...irip};
    }
  }

  print(id: string) {
    this.studentService.getIRIPPrint(this.districtId, this.studentId, id).subscribe(r => {
      let url = window.URL.createObjectURL(r);
      let pwa = window.open(url);
    });
  }

  close(canceled: boolean) {
    if (!canceled) {
      this.getData();
    }

    this.selectedIRIP = null;
  }

  delete(id: string) {
    this.studentService.deleteIRIP(this.districtId, this.studentId, id).subscribe(r => {
      this.getData();
    });
  }

  save() {
    this.selectedIRIP.id ? this.studentService.postIRIP(this.districtId, this.studentId, this.selectedIRIP).subscribe(data => {
      this.close(false);
    }) : this.studentService.patchIRIP(this.districtId, this.studentId, this.selectedIRIP).subscribe(data => {
      this.close(false);
    });
  }
}
