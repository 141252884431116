import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {District} from '../../models/district/district.model';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm, NgModel} from '@angular/forms';
import {FeatureDto} from './featureDto';
import {Level, ToastService} from '../../services/toast.service';

@Component({
  selector: 'sidr-admin-feature',
  templateUrl: 'adminFeature.component.html',
})
export class AdminFeatureComponent implements OnInit {
  feature: FeatureDto | null;

  constructor(
    private adminService: AdminService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private toasterService: ToastService
  ) {
    this.feature = null;
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      if (params['feature_id'] && params['feature_id'] !== 'new') {
        this.adminService.getFeature(params['feature_id']).subscribe(r => this.feature = r);
      } else {
        this.feature = new FeatureDto();
      }
    });
  }

  onSubmit(featureForm: NgForm) {
    if (this.feature.id && this.feature.id.length > 0) {
      this.adminService.updateFeature(this.feature).subscribe(r => {
        this.feature = r;
        this.toasterService.AddNotification(Level.SUCCESS, 'Feature Service', 'Feature saved successfully');
        this.router.navigate(['admin', 'features']);
      }, err => {
        this.toasterService.AddNotification(Level.ERROR, 'Feature Service', 'There was an error saving the feature');
      });
    } else {
      this.adminService.postFeature(this.feature).subscribe(r => {
        this.feature = r;
        this.toasterService.AddNotification(Level.SUCCESS, 'Feature Service', 'Feature saved successfully');
        this.router.navigate(['admin', 'features']);
      }, err => {
        this.toasterService.AddNotification(Level.ERROR, 'Feature Service', 'There was an error saving the feature');
      });
    }

  }
}
