<div class="d-flex card" *ngIf="intv">
  <div class="card-body" *ngIf="_config">
    <div class="row">
      <div class="col">Intervention</div>
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control" [name]="randKey+'_intv'" [(ngModel)]="intv.intervention" placeholder="Intervention" [disabled]="!editing">
          <span class="input-group-btn" ngbDropdown placement="bottom" *ngIf="editing">
            <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" *ngFor="let i of _config.intervention" (click)="$event.preventDefault(); intv.intervention = i">{{i}}</button>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">Implementer</div>
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control" [name]="randKey+'implementer'" [(ngModel)]="intv.implementer" placeholder="Implementer" [disabled]="!editing">
          <span class="input-group-btn" ngbDropdown placement="bottom" *ngIf="editing">
            <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" *ngFor="let i of _config.implementers" (click)="$event.preventDefault(); intv.implementer = i">{{i}}</button>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">How Often</div>
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control" [name]="randKey+'howOften'" [(ngModel)]="intv.how_often" placeholder="How Often" [disabled]="!editing">
          <span class="input-group-btn" ngbDropdown placement="bottom" *ngIf="editing">
            <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" *ngFor="let i of _config.howOften" (click)="$event.preventDefault(); intv.how_often = i">{{i}}</button>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">Start Date</div>
      <div class="col">
        <input type="date" class="form-control" [name]="randKey + 'start'" [(ngModel)]="intv.start_date" [disabled]="!editing">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">End Date</div>
      <div class="col">
        <input type="date" class="form-control" [name]="randKey + 'end'" [(ngModel)]="intv.end_date" [disabled]="!editing">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">Result</div>
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control" [name]="randKey+'result'" [(ngModel)]="intv.result" placeholder="Result" [disabled]="!editing">
          <span class="input-group-btn" ngbDropdown placement="bottom" *ngIf="editing">
            <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" *ngFor="let i of _config.results" (click)="$event.preventDefault(); intv.result = i">{{i}}</button>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer d-print-none">
    <div class="d-flex">
      <ng-container *ngIf="editing">
        <span class="mr-auto"></span>
        <button-cancel (btnClick)="cancel()" label="Cancel" class="mr-1"></button-cancel>
        <button-save (btnClick)="save()" label="Save"></button-save>
      </ng-container>
      <ng-container *ngIf="!editing">
        <button-edit (btnClick)="edit()"></button-edit>
        <button-delete (btnClick)="delete()"></button-delete>
      </ng-container>
    </div>
  </div>
</div>
