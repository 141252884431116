import {Component} from "@angular/core";
import {NgForm} from '@angular/forms';

class Login {
  username: string;
  password: string;
}

@Component({
  selector: `LoginPage`,
  templateUrl: 'loginPage.component.html',
})
export class LoginPageComponent {
  public login: Login = new Login();
  onSubmit(form: NgForm) {

  }
}
