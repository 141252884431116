<form #iripForm="ngForm" class="p-3" (ngSubmit)="save(iripForm)" novalidate>
  <div class="row mt-3">
    <div class="col-3 row-header">School Year</div>
    <div class="col-3">
      <div class="input-group">
        <input #schoolYear="ngModel" type="text" class="form-control" name="school_year" [(ngModel)]="irip.school_year" placeholder="School Year" required>
        <span class="input-group-btn" ngbDropdown placement="bottom">
            <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
            <div ngbDropdownMenu>
              <button type="button" class="dropdown-item" *ngFor="let i of school_years" (click)="$event.preventDefault(); yearChange(i)">{{i}}</button>
            </div>
          </span>
      </div>
      <p *ngIf="schoolYear.invalid && (schoolYear.dirty || schoolYear.touched)" class="text-danger">
        You must specify a school year
      </p>
    </div>
    <div class="col-3 row-header">Grade</div>
    <div class="col-3">
      <div class="input-group">
        <input class="form-control" name="grade" [(ngModel)]="irip.grade" #grade="ngModel" required>
        <span class="input-group-btn" ngbDropdown placement="bottom">
              <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
              <div ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let i of grades" (click)="$event.preventDefault(); gradeChange(i)">{{i}}</button>
              </div>
            </span>
      </div>
      <p *ngIf="grade.invalid && (grade.dirty || grade.touched)" class="text-danger">
        You must specify a grade level
      </p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Parent / Guardian*</div>
    <div class="d-print-none col-9 col-xl-3">
      <input class="form-control" name="guardian" [(ngModel)]="irip.guardian" #guardian="ngModel" required>
      <p *ngIf="guardian.invalid && (guardian.dirty || guardian.touched)"
         class="text-danger">
        You must specify a Parent or Guardian
      </p>
    </div>
    <div class="d-none d-print-block col-sm-6 col-3">{{irip.guardian}}</div>

    <div class="col-3 row-header">Teacher*</div>
    <div class="col-9 col-xl-3">
      <staff-select [edittable]="true" [districtId]="districtId" [(selected)]="irip.teacher_id" required="true"
                    (onChange)="staffChange($event)" [multiple]="false" #teacher></staff-select>
      <p *ngIf="teacher.invalid && teacher.touched" class="text-danger">You must specify a teacher.</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Data Dialogue Date*</div>
    <div class="d-print-none col-9 col-xl-3">
      <input class="form-control" name="date" type="date" [(ngModel)]="irip.date" #date="ngModel" required>
      <p *ngIf="date.invalid && (date.dirty || date.touched)"  class="text-danger">
        Please specify the date of the data dialogue
      </p>
    </div>
    <div class="d-none d-print-block col-9 col-xl-3">{{irip.date | date: 'shortDate' }}</div>

    <div class="col-3 row-header">Team Members</div>
    <div class="col-sm-9 col-xl-3">
      <staff-select [edittable]="true" [districtId]="districtId" [selected]="irip.team_members"
                    (selectedChange)="teamChange($event)" [multiple]="true"></staff-select>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 row-header">Assessment Results</div>
    <div class="col-12">
      <div class="container-fluid" *ngIf="irip.assessment_result_obj != null">
        <div class="row mb-1">
          <div class="col"></div>
          <div class="col-3 text-center">Fall</div>
          <div class="col-3 text-center">Winter</div>
          <div class="col-3 text-center">Spring</div>
        </div>
        <ng-container *ngFor="let item of irip.assessment_result_obj | keyvalue">
          <div class="row mb-1">
            <div class="col text-right">{{ getAssessmentName(item.key) }}</div>
            <div class="col-3">
              <input type="text" class="form-control" [name]="item.key + '_fall'" [(ngModel)]="irip.assessment_result_obj[item.key].fall">
            </div>
            <div class="col-3">
              <input type="text" class="form-control" [name]="item.key + '_winter'" [(ngModel)]="irip.assessment_result_obj[item.key].winter">
            </div>
            <div class="col-3">
              <input type="text" class="form-control" [name]="item.key + '_spring'" [(ngModel)]="irip.assessment_result_obj[item.key].spring">
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Assessment Results</div>
    <div class="d-print-none col">
      <textarea class="form-control" name="assessment_result"
                [(ngModel)]="irip.assessment_result"></textarea>
    </div>
    <div class="d-none d-print-block col">
      {{irip.assessment_result}}
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Other Performance Factor</div>
    <div class="col-9">
      <div class="d-print-none">
        <textarea class="form-control" name="other_factors"
                  [(ngModel)]="irip.other_factors"></textarea>
      </div>
      <div class="d-none d-print-block">{{irip.other_factors}}</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Strengths</div>
    <div class="col-9">
      <div class="d-print-none">
        <textarea class="form-control" name="strengths"
                  [(ngModel)]="irip.strengths"></textarea>
      </div>
      <div class="d-none d-print-block">{{irip.strengths}}</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Area(s) of Focus, Based on Assessments</div>
    <div class="col-9">
      <ul class="list-group">
        <li class="list-group-item d-flex flex-row" *ngFor="let aog of irip.areas_of_growth">
          <span class="mr-auto">{{aog}}</span>
          <button-delete btnClass="btn-sm d-print-none" (btnClick)="delAOG(aog)"></button-delete>
        </li>
        <li class="list-group-item d-flex flex-row d-print-none">
          <div class="input-group">
            <input type="text" class="form-control" name="newAOG" [(ngModel)]="newAOG">
            <span class="input-group-btn" ngbDropdown placement="bottom-right" *ngIf="_config">
              <button class="btn btn-outline-primary" ngbDropdownToggle (click)="$event.preventDefault();"></button>
              <div ngbDropdownMenu>
                <button type="button" class="dropdown-item" *ngFor="let aog of _config.areasOfGrowth" (click)="$event.preventDefault(); newAOG=aog; addAOG()">{{aog}}</button>
              </div>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Student Learning Goals</div>
    <div class="col-9">
      <div class="d-print-none">
        <textarea class="form-control" name="learning_goals"
                  [(ngModel)]="irip.learning_goals"></textarea>
      </div>
      <div class="d-none d-print-block">{{irip.learning_goals}}</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 row-header">Intervention/Strategies</div>
    <ng-container *ngFor="let intv of irip?.interventions; let i = index">
      <irip-intv [iripConfig]="_config" [(intv)]="irip.interventions[i]" (deleted)="removeIntv(i)" class="col-6 mb-2" (intvChange)="form.form.markAsTouched()" ></irip-intv>
    </ng-container>
    <irip-intv class="d-print-none col-6 mb-2" #newIntv [iripConfig]="_config"></irip-intv>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Intervention Notes</div>
    <div class="col d-print-none">
      <textarea class="form-control" name="result_of_intervention"
                [(ngModel)]="irip.result_of_intervention"></textarea>
    </div>
    <div class="d-none d-print-block">{{irip.result_of_intervention}}</div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Parent / Guardian Notification</div>
    <div class="col-3 d-print-none">
      <input type="date" name="guardian_notification_date" class="form-control"
             [(ngModel)]="irip.guardian_notification_date">
    </div>
    <div class="col-3 d-none d-print-block">{{irip.guardian_notification_date | date: 'shortDate'}}</div>

    <div class="col-3 row-header">Home Plan Provided Date</div>
    <div class="col-3 d-print-none">
      <input type="date" name="home_plan_provided" class="form-control"
             [(ngModel)]="irip.home_plan_provided">
    </div>
    <div class="col-3 d-none d-print-block">{{irip.home_plan_provided | date: 'shortDate'}}</div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Parent / Guardian Collaboration Date</div>
    <div class="col-3 d-print-none">
      <input type="date" name="guardian_collaboration_date" class="form-control"
             [(ngModel)]="irip.guardian_collaboration_date">
    </div>
    <div class="col-3 d-none d-print-block">{{irip.guardian_collaboration_date | date: 'shortDate'}}</div>
  </div>
  <div class="row mt-3">
    <div class="col-3 row-header">Notes from Parent / Guardian Collaboration</div>
    <div class="col-9 d-print-none">
        <textarea class="form-control" name="notes_from_guardian_collaboration"
                  [(ngModel)]="irip.notes_from_guardian_collaboration"></textarea>
    </div>
    <div class="col-9 d-none d-print-block">{{irip.notes_from_guardian_collaboration}}</div>
  </div>
</form>
